.menu-tabs {
  @media (max-width: 374px) {
    padding: 0;
  }
  .edit-container {
    .button-with-border {
      @media (max-width: 350px) {
        display: none;
      }
    }
    .icon-edit {
      display: none;
      color: $app-color;
      @media (max-width: 350px) {
        display: block;
      }
    }
    @media (max-width: 350px) {
      padding: 30px 0 0 0;
      margin-left: 10px!important;
    }
  }


}

.profile-tabs {
  list-style: none;
  padding: 30px 0 0 30px;
  @media (max-width: 576px) {
    & + div {
      padding-right: 20px;
    }
  }

  li {
    padding: 0 0 15px;
    margin-right: 40px;
    @media (max-width: 576px) {
      margin-right: 7px;
      font-size: 12px;
    }
    a {
      color: inherit;
      padding: 0 0 15px;
      position: relative;
      white-space: nowrap;
      &:before {
        opacity: 0;
        position: absolute;
        bottom: -5px;
        content: '';
        width: 100%;
        height: 2px;
        border: 2px solid $app-color;
        background: $app-color;
        border-radius: 3px;
        transition: opacity ease-out 0.5s, color ease-out 0.5s;
      }
    }

    .is-tab-active {
      &:before {
        opacity: 1;
        box-shadow: 0 10px 20px 0 rgb(26, 175, 233);
      }
      color: $app-color;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.images-preview-container {
  display: none;
  max-width: 120px;
  height: 80px;
  border: 1px solid #ced4da;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}