.ReactTable {
  font-size: 10px;
  font-weight: 400;
  background: #fff;
  padding: 4px;

  .rt-table {
    overflow-x: scroll!important;
  }

  .rt-td {
    height: 32px;
  }

  .rt-td {
    display: flex;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }
  .rt-thead.-header {
    //box-shadow: none!important;
    border-top: none !important;
  }
  .rt-thead {
    border-bottom: 1px solid var(--light-gray-bg) !important;
    .rt-resizable-header-content {
      white-space: initial!important;
    }
  }
  .rt-thead.-filters {
    padding: 2px 0;
    input {
      padding: 2px 6px!important;
    }
  }
  .rt-resizable-header-content {
    padding: 0 10px;
    text-align: center;
    line-height: 2;
  }

  .rt-th.-sort-asc {
    position: relative;
    box-shadow: none !important;

    &::before {
      content: '▲';
      position: absolute;
      right: 5px;
      bottom: 67%;
      color: #000;
    }

    &::after {
      content: '▼';
      position: absolute;
      right: 5px;
      top: 67%;
      color: #fff;
    }
  }

  .rt-th.-sort-desc {
    position: relative;
    box-shadow: none !important;

    &::before {
      content: '▲';
      position: absolute;
      right: 5px;
      bottom: 50%;
      color: #fff;
    }

    &::after {
      content: '▼';
      position: absolute;
      right: 5px;
      top: 50%;
      color: #000;
    }
  }

  .rt-th {
    font-weight: 600;
  }

  .rt-tr-group {
    border-color: var(--light-gray-bg) !important;
  }

  .-pagination input {
    background: var(--light-gray-bg)!important;
  }

  .pagination-bottom {
    .-pagination {
      border-top: 0;
      .-btn {
        border: 1px solid rgba(0,0,0,0.1);
        background: rgba(0,0,0,0.1);
        color: #000;
      }
    }
  }

  .rt-noData {
    padding: 0!important;
  }
}