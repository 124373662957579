@font-face {
  font-family: "Playfair-Bold";
  src: url("/assets/fonts/datta/fonts/PlayfairDisplay-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay-Regular";
  src: url("/assets/fonts/datta/fonts/PlayfairDisplay-Regular.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("/assets/fonts/datta/fonts/Gilroy-Regular.ttf");
  font-weight: 700;
  font-style: normal;
}

.product-cards{
  max-width: 1430px;

  .variable-product{
    position: relative;
    &:before {
      content: 'Up to';
      position: absolute;
      font-size: 12px;
      bottom: -10px;
      font-family: "Gilroy-Regular", sans-serif;
    }
  }
  .am-number {
    font-family: "Playfair-Bold", sans-serif;
  }
  height: 100%;
  .product-card-wrap {
    width: 100%;
    min-width: 300px;
    margin-bottom: 30px;
    @media (max-width: 576px) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .product-card {
    border-left: 2px solid $app-color;
    border-radius: 4px;
    background-image:  url("/img/product-card-bg2.png");
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    //min-width: 205px;
    box-shadow: 4px 4px 20px -3px rgba(0,0,0,0.2);


    &-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      //margin-bottom: 20px;
      i {
        font-size: 20px;
      }
      .padding-left {
        padding-left: 6px;
      };
    }
    @media (max-width: 992px) {
      min-width: initial;
      max-width: initial;
    }

  }
  .prod-group {
    letter-spacing: 2.1px;
    font-family: "PlayfairDisplay-Regular", sans-serif;
  }
  @media (max-width: 320px) {
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
  }
}
